@use '../abstracts/mixins' as *;

.hamburger {
  --line-height: 0.25rem;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
  background: transparent;
  padding: 1rem;
  border: none;
  cursor: pointer;
  width: 4.5rem;

  span {
    height: var(--line-height);
    width: 100%;
    background: var(--clr-secondary);
  }

  &::before,
  &::after {
    content: '';
    height: var(--line-height);
    background: var(--clr-secondary);
    width: 100%;
    position: relative;
    transition: all ease 0.2s;
  }

  &::before {
    left: 0;
  }

  &::after {
    top: 0;
    left: 0;
  }
}

.nav--open ~ .hamburger {
  &::before {
    top: 0.75rem;
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
    top: -0.75rem;
  }
  span {
    opacity: 0;
  }
}

@include mq(medium) {
  .hamburger {
    display: none;
  }
}
