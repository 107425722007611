@use '../abstracts/mixins' as *;

.rating {
  display: grid;
  grid-auto-flow: column;
  place-items: center;
  grid-template-rows: repeat(2, auto);
  width: min(100% - 2rem, 1000px);

  &__stars {
    display: inline-flex;
    align-items: center;
  }

  &__star {
    display: inline-flex;
    width: 40px;
    height: 40px;
    position: relative;

    & > svg {
      position: absolute;
      inset: 0;
    }
  }
}

@include mq(small) {
  .rating {
    grid-template-columns: auto 1fr;
  }
}
