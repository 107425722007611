@use '../abstracts/mixins' as *;

.nav {
  &__contact {
    &--mobile-only {
      align-self: center;
      margin-block-start: 3rem;
    }

    .streamfield {
      margin: 0;
      width: 100%;
    }

    &.popover {
      position: initial;
      display: inline-flex;
      gap: 0.5rem;
      align-items: center;
      padding: 1rem 1.5rem;
      border-radius: 0.625rem;
      text-decoration: none;
      background-color: var(--clr-primary);
    }

    &.popover__trigger {
      font-size: var(--fs-400);
      font-weight: 700;
      color: var(--clr-white);
    }

    &.popover__content {
      z-index: 2;
      overflow: hidden;
      padding: 0;
    }
  }

  &__trigger {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
  }

  &__trigger,
  &__link {
    padding: 0.5rem 0.75rem;
    outline: none;
    user-select: none;
    font-weight: 600;
    line-height: 1;
    border-radius: 0.25rem;
    color: var(--clr-secondary);

    &:focus {
      position: relative;
      box-shadow: 0 0 0 2px var(--clr-light-grey);
    }

    &:hover {
      background-color: var(--clr-light-grey);
    }
  }

  &__content-list {
    list-style: none;
  }

  &__link {
    color: var(--clr-secondary);
    text-decoration: none;
    font-weight: 600;

    &:hover,
    &.active {
      text-decoration: underline;
    }
  }

  &__caret {
    position: relative;
    transition: transform 250ms ease;

    [data-state='open'] & {
      transform: rotate(180deg);
    }
  }
}

@include mq(medium, max) {
  .nav {
    --header-offset: 6rem;

    position: fixed;
    top: var(--header-offset);
    left: 0;
    width: 80vw;
    z-index: 10;
    overflow: hidden;
    transition: height 0.2s ease;
    background-color: var(--clr-secondary);
    transform: translateX(-105%);
    transition: transform 300ms ease-in-out;
    height: calc(100vh - var(--header-offset));

    &--open {
      transform: translateX(0);
    }

    &__content-list,
    &__list {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    &__content-list {
      padding-block: 0.75rem 1.5rem;
    }

    &__list-item,
    &__sub-list-item {
      padding: 0;
      margin: 0;
    }

    &__list-item {
      border-bottom: 1px solid white;
    }

    &__trigger,
    &__link {
      padding: 0.5rem 1rem;
      color: var(--clr-white);
      font-weight: 500;

      &:focus {
        position: relative;
        box-shadow: 0 0 0 2px var(--clr-light-grey);
      }

      &:hover {
        background-color: inherit;
      }
    }

    &__trigger {
      width: 100%;
      border-radius: 0;
    }

    &__link {
      display: inline-block;
    }

    &__list-item > &__link,
    &__list-item > &__trigger {
      padding: 1rem;
      font-weight: 400;
      font-size: var(--fs-600);
    }

    &__caret {
      right: 1.5rem;
    }
  }
}

@include mq(medium) {
  .nav {
    --justify: flex-end;

    position: relative;
    display: flex;
    justify-content: var(--justify, center);
    width: 100%;
    z-index: 1;

    &__contact--mobile-only {
      display: none !important;
    }

    &__list {
      all: unset;
      display: flex;
      gap: 1.2rem;
      justify-content: center;
      background-color: white;
      padding: 0.25rem;
      border-radius: 6px;
      // box-shadow: 0 2px 10px rgb(0 0 0 / 0.2);
    }

    &__trigger,
    &__link {
      padding: 8px 12px;
    }

    &__link {
      display: block;
      text-decoration: none;
      line-height: 1;
      cursor: pointer;
    }

    &__indicator {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 10px;
      top: 100%;
      overflow: hidden;
      z-index: 1;
      transition: width 250ms ease, transform 250ms ease;

      &[data-state='visible'] {
        animation: fadeIn 200ms ease;
      }

      &[data-state='hidden'] {
        animation: fadeOut 200ms ease;
      }
    }

    &__arrow {
      position: relative;
      top: 70%;
      background-color: white;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      border-top-left-radius: 2px;
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;

      @media (prefers-reduced-motion: no-preference) {
        animation-duration: 250ms;
        animation-timing-function: ease;

        &[data-motion='from-start'] {
          animation-name: enterFromLeft;
        }

        &[data-motion='from-end'] {
          animation-name: enterFromRight;
        }

        &[data-motion='to-start'] {
          animation-name: exitToLeft;
        }

        &[data-motion='to-end'] {
          animation-name: exitToRight;
        }
      }
    }

    &__content-list {
      padding: 22px;
      margin: 0;
      width: 500px;
    }

    &__viewport-position {
      position: absolute;
      display: flex;
      justify-content: flex-end; // var(--justify, center);
      width: 100%;
      top: 100%;
      left: 0;
      perspective: 2000px;
    }

    &__viewport {
      position: relative;
      transform-origin: top center;
      margin-top: 10px;
      width: 100%;
      width: var(--radix-navigation-menu-viewport-width);
      background-color: white;
      border-radius: 6px;
      overflow: hidden;
      box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
      height: var(--radix-navigation-menu-viewport-height);

      @media (prefers-reduced-motion: no-preference) {
        transition: width 300ms ease, height 300ms ease;

        &[data-state='open'] {
          animation: scaleIn 200ms ease;
        }
        &[data-state='closed'] {
          animation: scaleOut 200ms ease;
        }
      }
    }
  }
}

@keyframes enterFromRight {
  from {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enterFromLeft {
  from {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes exitToRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(200px);
    opacity: 0;
  }
}

@keyframes exitToLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-200px);
    opacity: 0;
  }
}

@keyframes scaleIn {
  from {
    transform: rotateX(-30deg), scale(0.9);
    opacity: 0;
  }
  to {
    transform: rotateX(0deg), scale(1);
    opacity: 1;
  }
}

@keyframes scaleOut {
  from {
    transform: rotateX(0deg) scale(1);
    opacity: 1;
  }
  to {
    transform: rotateX(-10deg), scale(0.95);
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
