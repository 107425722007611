.reservation {
  text-align: center;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    background: var(--clr-light-grey);
    padding: 2.5rem 1rem;
    max-width: 700px;
    margin-inline: auto;
  }

  &__link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &--telephone {
      background: transparent;
    }
  }
}
