@use '../abstracts/mixins' as *;
@use '../utilities/container';

.hero {
  --flow-spacer: 4rem;
  @extend .container !optional;
  margin-block-start: 0;

  &__image {
    max-height: 500px;
    overflow: hidden;
  }

  &__title {
    text-align: center;
  }
}
