@use '../abstracts/mixins' as *;

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;

  &__link {
    text-decoration: none;
    text-align: center;
    width: 100%;
    max-width: 80vw;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include mq(small) {
  .buttons {
    &__link {
      width: unset;
      max-width: unset;
    }
  }
}
