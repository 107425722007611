@use '../abstracts/mixins' as *;

.accordion {
  --flow-spacer: 1.25rem;
  --padding: 1rem;

  overflow: hidden;

  &__header {
    all: unset;
    display: flex;
  }

  &__trigger {
    all: unset;
    border-radius: var(--border-radius);
    background-color: var(--clr-secondary);
    color: var(--clr-white);
    padding: var(--padding);
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: var(--fs-600);
    font-weight: 600;
    line-height: 1.3;
    cursor: pointer;
  }

  &__icon {
    --inset: 0px;

    color: #ecf0f1;
    width: var(--fs-500);
    height: var(--fs-500);
    border: 0;
    font-size: 1.5em;
    position: relative;

    & span {
      position: absolute;
      transition: 300ms;
      background: white;
      border-radius: 2px;
    }

    /* Create the "+" shape by positioning the spans absolutely */
    & span:first-child {
      top: var(--inset);
      bottom: var(--inset);
      width: 2px;
      left: 50%;
      transform: translateX(-50%);
    }

    & span:last-child {
      left: var(--inset);
      right: var(--inset);
      height: 2px;
      top: 50%;
      transform: translateY(-50%);
    }

    /* Morph the shape when the & is hovered over */
    [data-state='open'] & span {
      transform: rotate(90deg);
    }

    [data-state='open'] & span:last-child {
      left: 50%;
      right: 50%;
    }
  }

  &__content[data-state='closed'] {
    display: none;
  }

  &__content {
    overflow: hidden;
    padding: var(--padding) 1.25rem;

    ul {
      & > * + * {
        margin-block-start: 0.5rem;
      }
    }

    &[data-state='open'] {
      // animation: slideDown 100ms linear forwards;
    }

    &[data-state='closed'] {
      // animation: slideUp 100ms linear forwards;
    }
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@include mq(small) {
  .accordion {
    &__content {
      padding: var(--padding) 3.25rem;
    }
  }
}
