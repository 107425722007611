// Declare font here

/* exo-regular - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''), url('../assets/fonts/exo/exo-v19-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../assets/fonts/exo/exo-v19-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* exo-600 - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''), url('../assets/fonts/exo/exo-v19-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../assets/fonts/exo/exo-v19-latin-600.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* exo-700 - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''), url('../assets/fonts/exo/exo-v19-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../assets/fonts/exo/exo-v19-latin-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
