@use '../abstracts/mixins' as *;

.eye-catcher {
  width: min(100% - 2rem, 1000px);

  &__link:focus-visible:focus-within &__text {
    outline: 4px solid var(--clr-secondary);
    outline-offset: 4px;
  }

  &__text {
    width: 150px;
    height: 150px;
    background: var(--clr-secondary);
    color: var(--clr-white);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 100vmax;
    font-size: var(--fs-600);
    margin-inline: auto;
  }
}

@include mq(small) {
  .eye-catcher {
    --spacer: 0;
    isolation: isolate;

    &.streamfield {
      position: relative;
      z-index: 1;
    }

    &__text {
      display: inline-flex;
      top: 0;
      right: 0;
      position: absolute;
      transform: translateY(-75%);
      z-index: 1;
    }
  }
}
