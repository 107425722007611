.richtext {
  h2 {
    margin-block-start: 0;
    margin-block-end: 1rem;
  }

  h3 {
    margin-block-start: 0;
    margin-block-end: 1rem;
  }

  p + h2 {
    margin-block-start: 2rem;
  }

  h2 + h3,
  p + h3 {
    margin-block-start: 2rem;
  }

  ul {
    padding: 0;
    padding-inline-start: 1.25rem;
  }

  p + p {
    margin-block-start: 0.75rem;
  }

  a {
    color: var(--clr-secondary);
  }
}
