@use '../abstracts/mixins' as *;
@use '../utilities/container';

.header {
  @extend .container !optional;
  margin-block: 0;
  padding-block: 1em;
  background-color: var(--clr-white);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__logo {
    height: 3rem;
    aspect-ratio: 2 / 1;
  }

  &__contact {
    display: none;
  }

  &__link {
    display: inline-block;
    padding: 1.5rem;
  }
}

@include mq(medium, max) {
  .header {
    position: fixed;
    isolation: isolate;
    z-index: 1;
    height: 6rem;
    left: 0;
    right: 0;
  }
}

@include mq(medium) {
  .header {
    gap: 2.5rem;

    &__logo {
      height: 4rem;
    }
  }
}
