@use '../utilities/container';

* {
  // this is a bad idea, but works for now
  font-variation-settings: 'wght' var(--font-weight, 375), 'wdth' var(--font-width, 80);
}

body {
  font-size: var(--fs-500);
  font-family: 'Exo', sans-serif;
  color: var(--clr-dark-grey);
  letter-spacing: -0.5px;
}

h1,
h2,
h3,
h4 {
  --font-width: 80;
  font-weight: normal;
  font-stretch: 100%;
  line-height: 1.1;
  color: var(--clr-dark-grey);
}

// This is setting the font-sizes
// based on the ones in the abstract
// folder, go there to make updates

h1,
.h1 {
  font-size: --fs-800;
  font-weight: 700;
  margin-block: 0;
  margin-block: 2.5rem;
}

h2,
.h2 {
  --flow-spacer: 1.5em;
  font-size: --fs-700;
  font-weight: 700;
  margin-block-start: 5rem;
  margin-block-end: 2.5rem;
}

h3,
.h3 {
  --flow-spacer: 1.5em;
  font-size: --fs-600;
  font-weight: 700;
}

h4,
.h4 {
  font-size: --fs-500;
}

small,
.text-small {
  font-size: var(--fs-300);
}

strong {
  font-weight: var(--font-weight, 600);
}

a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

// p:not([class]),
// ul:not([class]),
// li:not([class]) {
//   max-width: 60ch;
// }
